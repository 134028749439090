import React, { useState, useEffect } from 'react';
import './custompush.scss';
import * as Headless from "./custompush.headless";
import {
    useNavigate,
  } from "react-router-dom";
import { Dropdown, Modal } from 'react-bootstrap';
import dayjs from 'dayjs';
import Loader from "react-loader-spinner";
import _ from 'lodash';
import Sidebar from "react-sidebar";
import DataTable from 'react-data-table-component';
import Pagination from '../../components/Pagination';
import { customTableStyles } from '../../utils/styles'
import actionIcon from '../../../assets/svg/actionIcon.svg';
import CustomToast from '../../components/CustomToast';
import ConfirmPopup from '../../components/confirmPopup';
import { CreateCustomPush } from './createCustomPush';
import { log } from 'winston';

export function CustomPush() {
    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(1);
    const [pages, setPages] = useState(1);
    const [total, setTotal] = useState(0);
    const [data, setData] = useState([]);
    const [filters, setFilters] = useState({});
    const [loading, setLoading] = useState(true);
    const [search, setSearch] = useState('');
    const [openFilter, setOpenFilter] = useState(false)
    const [show, setShow] = useState(false);
    const [showdel, setShowdel] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState({ variant: 'success', message: '' });
    const [chosenId, setChosenId] = useState('');
    const [selectedCountry, setSelectedCountry] = useState('');
    const [reBroadcast, setReBroadcast] = useState({});
    const [custonPushData, setCustomPushData] = useState({
        title: '',
        message: '',
        countries:[],
        type:1,
        scheduleType:1,
        teams:[],
        pushType:3
      });
    const [editPressed, setEditPressed] = useState(false);
    const columns = [
        {
          name: <th>#</th>,
          selector: (row, index) => index + (limit * (page - 1)) + 1,
          cell: (row, index) =>
            <td className="d-flex">{index + (limit * (page - 1)) + 1}</td>,
          grow: 0.05,
          width: '60px'
        },
        {
          name: <th>TITLE</th>,
          selector: (row, index) => row.title.toLowerCase(),
          cell: (row, index) =>
            <td style={{ maxWidth: 150 }}>
              {row.title}
            </td>,
          sortable: true,
        },
        {
            name: <th>MESSAGE</th>,
            selector: (row, index) => row.message.toLowerCase(),
            cell: (row, index) =>
              <td style={{ maxWidth: 150 }}>
                {row.message}
              </td>,
            sortable: true,
        },  
       
        {
          name: <th>notification TYPE</th>,
          selector: (row, index) => row.countries && row.countries.length>0. ? "Country(s)" : "Teams(s)",
          cell: (row, index) =>
            <td style={{ maxWidth: 150 }}>
              {row.countries && row.countries.length>0 ? "Country(s)" : "Teams(s)"}
            </td>,
          sortable: true,
        },
        {
          name: <th>CREATED ON</th>,
          selector: (row, index) => row.createdAt ? row.createdAt : 'N/a',
          cell: (row, index) =>
            <td>
              {dayjs(row.createdAt).format('DD MMM YYYY hh:mm A')}
            </td>,
          sortable: true,
          grow: 1.5,
        },
        {
          name: <th>BROADCASTED ON</th>,
          selector: (row, index) => row.broadcastDate ? row.broadcastDate : 'N/a',
          cell: (row, index) =>
            <td>
              {dayjs(row.broadcastDate).format('DD MMM YYYY hh:mm A')}
            </td>,
          sortable: true,
          grow: 1.5,
        },
        {
          name: <th> STATUS</th>,
          selector: row => row.status,
          sortable: true,
          cell: (row) =>
            <td>{
              row.status=="Completed" ?
                <span className="btn btn-sm btn-success">{row.status}</span> :
                <span className="btn btn-sm btn-secondary">{row.status}</span>
            }
            </td>,
        },
        {
          name: <th>ACTIONS</th>,
          selector: (row, index) => 'actions',
          cell: (row, index) =>
            <td className='table-action-column'>
              {actionButton(row)}
            </td>,
        },
      ]

      const actionButton = (row) => {
        return (
          <Dropdown drop="up">
            <Dropdown.Toggle variant="default" id="dropdown-basic" className='actionDropdown'>
              <img alt="" src={actionIcon} width={30} height={30} />
            </Dropdown.Toggle>
            <Dropdown.Menu className='custom-dropdown'>
              <Dropdown.Item href="#" onClick={(e) => { setChosenId(row._id); onButtonPress('rebroadcast', row) }}>Re-broadcast</Dropdown.Item>
              <Dropdown.Item href={`/management/customPush/${row._id}`} onClick={(e) => {}} >View</Dropdown.Item>
              <Dropdown.Item href="#" onClick={(e) => { setChosenId(row._id); setShowdel(!showdel) }}>Delete</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        )
      }
      useEffect(() => {
        getAllPush(limit, page, search, filters);
      }, [page]);
    
      useEffect(() => {
        getAllPush(limit, 1, search, filters);
      }, [limit, search, filters])
      const getAllPush = async (l, p, s, filter) => {
        setLoading(true);
        try {
          const response = await Headless.getBroadcastList(l, p, s, filter);
          console.log(response.data);
          
          if (response && response.data && response.data.pages) {
            setPages(response.data.pages);
          }
          if (response && response.data && response.data.total) {
            setTotal(response.data.total);
          }
          if (response && response.data && response.data.data) {
            console.log("dataa",response.data.data)
            setData(response.data.data);
          }
          setLoading(false);
        } catch (err) {
          setPages(1);
          setTotal(0);
          setData([]);
          setLoading(false);
        }
      }
      const onButtonPress = async (from, row) => {
        if (from === 'rebroadcast') {
          // setEditPressed(true);
          let values={
            title: row.title,
            message: row.message,
            countries:row.countries && row.countries.length>0 ? row.countries : [],
            type:row.type,
            teams:row.teams&&row.teams.length>0?row.teams:[],
            tabType:row.tabType,
            scheduleType:row.scheduleType,
            pushType:row.pushType
          }
          console.log('values are',values)
          setReBroadcast(values);
          setShow(!show)
          // onCreateCustomPush(values);
        } else {
          setEditPressed(false);
          setCustomPushData({
            title: '',
            message: '',
            countries:[],
            type:1,
            teams:[],
            scheduleType:1,
            pushType:3
          })
          setSelectedCountry('');
          setShowModal(!showModal);
        }
        
      }
      const onToggleLimit = (e, value) => {
        e.preventDefault();
        setPage(1)
        setLimit(value);
      };
      const onSearch = _.debounce(setSearch, 500);
      const onRebroadcastConfirm = async () => {
        try {
      
        onCreateCustomPush(reBroadcast);
        } catch (err) {
          setToastMessage({
            variant: 'danger',
            message: 'Re-boroadcast failed!'
          })
          setShowToast(true);
          setChosenId('');
        }
      }
      const onEditCustomPush = (values) => {
        setShowModal(false);
        Headless.editTax(chosenId, values).then(() => {
          setToastMessage({
            variant: 'success',
            message: 'Tax details edited successfully!'
          })
          setShowToast(true);
        //   getTaxes(limit, page, search, filters);
          setChosenId('');
          setEditPressed(false);
        })
          .catch(err => {
            setToastMessage({
              variant: 'danger',
              message: 'Editing tax failed!'
            })
            setShowToast(true);
            setChosenId('');
            setEditPressed(false);
          })
      }
      const onCreateCustomPush = (values) => {
        console.log("instde create push");
        setLoading(true)
        setShowModal(false);
        Headless.createCustomPush(values).then(() => {
          setToastMessage({
            variant: 'success',
            message: 'Broadcast send to users successfully!'
          })
          setShowToast(true);
          getAllPush(limit, page, search, filters);
          setLoading(false)
        })
          .catch(err => {
            setToastMessage({
              variant: 'danger',
              message: err.response && err.response.data && err.response.data.status ? err.response.data.status : 'Sending broadcast failed!'
            })
            setShowToast(true);
          })
      }

      const onDeleteConfirm = async () => {
        try {
          const response = await Headless.deleteBroadcast(chosenId);
          setChosenId('');
          setToastMessage({
            variant: 'success',
            message: 'Broadcast deleted successfully!'
          })
          setShowToast(true);
          getAllPush(limit, page, search, filters);
        } catch (err) {
          console.log(err);
          setToastMessage({
            variant: 'danger',
            message: 'Deleting broadcast failed!'
          })
          setShowToast(true);
          setChosenId('');
        }
      }
  return (
   <>
   <div className="card h-100">
        <Sidebar
          pullRight
          // sidebar={filter()}
          open={openFilter}
          styles={{
            sidebar:
            {
              background: "white",
              overflow: "visible",
              zIndex: 20
            },
            overlay:
            {
              backgroundColor: "transparent"
            }
          }}
        >
          <div className="card-body">
            <div className="d-flex align-items-center table-header-menu justify-content-between">
              <div className="d-flex align-items-center" />
              <div className="d-flex align-items-center">
                <div className="d-flex" onClick={() => { onButtonPress('create') }}>
                  <span className="btn filter-btn">
                  Create new notification
                  </span>
                </div>
              </div>
            </div>
            <div className="d-flex align-items-center table-header-menu justify-content-between">
              <div className="d-flex align-items-center">
                <p className="mr-3">Show</p>
                <p className="mr-3">{limit}</p>
                <Dropdown>
                  <Dropdown.Toggle variant="default" id="dropdown-basic">
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item href="#" onClick={(e) => onToggleLimit(e, 10)}>10</Dropdown.Item>
                    <Dropdown.Item href="#" onClick={(e) => onToggleLimit(e, 25)}>25</Dropdown.Item>
                    <Dropdown.Item href="#" onClick={(e) => onToggleLimit(e, 50)}>50</Dropdown.Item>
                    <Dropdown.Item href="#" onClick={(e) => onToggleLimit(e, 100)}>100</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div className="d-flex align-items-center">
                <p className="mr-3">Search</p>
                <input className="form-control" placeholder="Search" onChange={(e) => onSearch(e.target.value.trim())} />
                {/* <span className="btn filter-btn" onClick={() => setOpenFilter(true)}>
                  Filter
                  {Object.keys(filters).length > 0 ?
                    <span>
                      &#9733;
                    </span>
                    : null}
                </span> */}
              </div>
            </div>
            {!loading && data.length > 0 ?
              <div className="dataHeader">
                <p className="mr-3">
                  Showing {page > 1 ? (page - 1) * limit : 1} to {page * limit < total ? page * limit : total} of {total} entries {Object.keys(filters).length > 0 ? `(filtered)` : ''}
                </p>
              </div>
              : null}
            <div className="table-responsive">
              {loading ? <div className="d-flex justify-content-center">
                <Loader
                  type="TailSpin"
                  color="#000"
                  height={30}
                  width={30}
                />
              </div> :
                <DataTable
                  columns={columns}
                  data={data}
                  customStyles={customTableStyles}
                />
              }
              
            </div>
          </div>
          {!loading && data.length > 0 ?
            <div className="d-flex card-footer align-items-center justify-content-between">
              <p className="page-data-count">
                Showing {page > 1 ? (page - 1) * limit : 1} to {page * limit < total ? page * limit : total} of {total} entries
              </p>
              <div>
                <Pagination pages={pages} current={page} onChange={setPage} />
              </div>
            </div>
            : null}
        </Sidebar>
        <ConfirmPopup show={show} message="Are you sure to re-broadcast this message?" submitButtonText='Confirm' cancelButtonText='Close' handleConfirm={() => { setShow(false); onRebroadcastConfirm() }} handleDismiss={() => { setShow(false) }} />
        <ConfirmPopup show={showdel} message="Are you sure to delete this broadcast?" submitButtonText='Confirm' cancelButtonText='Close' handleConfirm={() => { setShowdel(false); onDeleteConfirm() }} handleDismiss={() => { setShowdel(false) }} />
        <Modal show={showModal} size="lg" onHide={() => {
          setShowModal(false); if (editPressed) {
            setEditPressed(false);
            setChosenId('');
          }
        }} centered>
          <Modal.Header closeButton>
            <Modal.Title>{editPressed ? 'Edit Broadcast' : ' Add Broadcast'}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <CreateCustomPush onCancel={() => {
              setShowModal(false); setChosenId('');
              setEditPressed(false);
            }} createCustomPush={(values) => { editPressed ? onEditCustomPush(values) : onCreateCustomPush(values) }} data={custonPushData} fromEdit={editPressed ? true : false} selectedCountry={selectedCountry} />
          </Modal.Body>
        </Modal>
        <CustomToast variant={toastMessage.variant} message={toastMessage.message} show={showToast} onClose={() => { setShowToast(false) }} />
        </div>
   </>
  )
}
