import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import './studios.scss';
import * as Headless from "./studio.headless.js";
import {
  NavLink,
  useNavigate,
  useLocation,
} from "react-router-dom";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { Dropdown, Modal, Button } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import Loader from "react-loader-spinner";
import _ from 'lodash';
import Sidebar from "react-sidebar";
import DatePicker from "react-datepicker";
import 'reactjs-popup/dist/index.css';
import "react-datepicker/dist/react-datepicker.css";
import Pagination from '../../components/Pagination.js';
import { parseCountryList } from '../../utils/functions.js';
import { customTableStyles } from '../../utils/styles.js'
import actionIcon from '../../../assets/svg/actionIcon.svg';
import CustomToast from '../../components/CustomToast.js';
import Header from '../../components/header.js';
import { format } from '../../utils/dateFunctions.js';
import CountryDropdown from '../../components/countryDropdown.js';
import { getNewCountryList } from '../../slices/auth.slice.js';
import ConfirmPopup from '../../components/confirmPopup.js';

export function Studios(props) {
  let location = useLocation();
  const ownerId = location && location.state && location.state.id ? location.state.id : undefined;
  const ownerName = location && location.state && location.state.name ? location.state.name : undefined;
  console.log("owner id id", ownerId);
  const countryRaw = useSelector(getNewCountryList)
  const navigate = useNavigate();
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(1);
  const [total, setTotal] = useState(0);
  const [studios, setStudios] = useState([]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState('');
  const [reload, setReload] = useState(false);
  const [showWarning, setShowWarning] = useState(null);
  const [error, setError] = useState(null);

  const [openFilter, setOpenFilter] = useState(false)
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [selectedStatus, setSelectedStatus] = useState(undefined);
  const [selectedCountry, setSelectedCountry] = useState({ name: '' });
  const [filters, setFilters] = useState({});
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState({ variant: 'success', message: '' });
  const [countries, setCountries] = useState([]);
  const [show, setShow] = useState(false);
  const [chosenId, setChosenId] = useState('');

  useEffect(() => {
    if (countryRaw.length > 0) {
      setCountries(parseCountryList(countryRaw));
    }
  }, [countryRaw])

  const getStudios = async (l, p, s, filter) => {
    console.log("get studios calling ");
    setLoading(true);
    try {
      const response = await Headless.getStudioList(l, p, s, filter, ownerId);
      console.log("Owner Data in studios",response.data);
      if (response.data && response.data.data) {

        // console.log("venues",response.data.data.data)
        setPages(response.data.data.pages);
        setTotal(response.data.data.total);
        setStudios(response.data.data.data);
        setPage(response.data.data.page)
      }
      setLoading(false);
    } catch (err) {
      setPages(1);
      setTotal(0);
      setStudios([]);
      setLoading(false);
    }
  }
  
  const onSearch = _.debounce(setSearch, 500);
  useEffect(() => {
    getStudios(limit, 1, search, filters);
  }, [limit,search,filters,reload])

  // useEffect(() => {
  //   getVenues(limit, 1, search, filters);
  // }, [limit])

  // useEffect(() => {
  //   getVenues(limit, 1, search, filters);
  // }, [search])

  // useEffect(() => {
  //   getVenues(limit, 1, search, filters);
  // }, [filters])

  // useEffect(() => {
  //   getVenues(limit, page, search, filters);
  // }, [reload])

  const onToggleLimit = (e, value) => {
    e.preventDefault();
    setLimit(value);
  };

  const changePage = (num) => {
    getStudios(limit, num, search, filters);
  }

  const onPressFilter = () => {
    let filter = {}
    if (startDate != '' && endDate != '') {
      filter['startDate'] = format(startDate, 'YYYY-MM-DD');
      filter['endDate'] = format(endDate, 'YYYY-MM-DD')
    }

    if (selectedStatus != undefined || selectedStatus == false) {
     
      filter['status'] = selectedStatus;
    }

    if (selectedCountry.name != '') {
      filter['country'] = selectedCountry.name;
    }

    setFilters(filter)
    setOpenFilter(false)
  }

  const onPressClear = () => {
    setStartDate('')
    setEndDate('')
    setSelectedStatus(undefined)
    setSelectedCountry({ name: '' })
    setFilters({});
  }

  const handleRadio = (value) => {
    if (value != selectedStatus) {
      if (value === 'true') {
        setSelectedStatus(true)
      } else {
        setSelectedStatus(false);
      }
    } else {
      setSelectedStatus(undefined)
    }
  }

  const columns = [
    {
      name: <th>#</th>,
      selector: (row, index) => index + (limit * (page - 1)) + 1,
      cell: (row, index) =>
        <td>{index + (limit * (page - 1)) + 1}</td>,
      grow: 0.05,
      width: '60px'
    },
    {
      name: <th>{`NAME`}</th>,
      selector: row => row.studioName,
      sortable: true,
      cell: (row) =>
        <td style={{ maxWidth: 250 }}>
          <div className='custom-row align-items-center d-flex'>
            {/* <OverlayTrigger trigger="hover" placement="bottom" overlay={<Popover id="popover-basic">
              <Popover.Content>
                {row.isAcceptPayment ? 'Accept payments' : 'Not accept payments'}
              </Popover.Content>
            </Popover>}>
              <span className={`book-status ${row.isAcceptPayment ? 'bookable' : 'not-bookable'}`}></span>
            </OverlayTrigger> */}

            <NavLink to={`${row._id}`} style={{ paddingTop: 10, paddingBottom: 10 }}>
              <div className="username">
                <p>{row.studioName}</p>
              </div>
            </NavLink>
          </div>
        </td>,
    },
    {
      name: <th>LOCATION</th>,
      selector: row => row.location && row.location.name ? row.location.name : 'N/a',
      cell: (row) =>
        <td style={{ maxWidth: 250 }}>
          {row.location && row.location.name ? row.location.name : 'N/a'}
        </td>,
      sortable: true,
      grow: 1.5
    },
    {
      name: <th>AREA</th>,
      selector: row => row.studioArea ,
      cell: (row) =>
        <td style={{ maxWidth: 150 }}>
          {row.studioArea  ? row.studioArea : 'N/a'}
        </td>,
      sortable: true,
      grow: 1.5
    },
    {
      name: <th>OWNER</th>,
      selector: row => row.owner,
      sortable: true,
      cell: (row) =>
        <td style={{ maxWidth: 150 }}>
          {row.ownerId ?
            <NavLink to={`../studios/studioOwners/${ row.owner && row.owner._id ? row.owner._id : ''}`} className="d-flex" style={{ paddingTop: 10, paddingBottom: 10 }} target="_blank">
              <div className="username">
                <p>{row.ownerId && row.ownerId.name ? row.ownerId.name : 'NA'}</p>
              </div>
            </NavLink>
            : ""
          }
        </td>,
    },
    {
      name: <th>STATUS</th>,
      selector: row => row.isPublished,
      sortable: true,
      cell: (row) =>
        <td style={{ backgroundColor: row.isPublished ? "green" : "orange", padding: 5, borderRadius: 5, color: 'white', textAlign: 'center', verticalAlign: 'center' }}>
          {row.isPublished ? "Published" : "Draft"}
        </td>,
    },
    {
      name: <th>ACTIONS</th>,
      selector: (row, index) => 'actions',
      cell: (row, index) =>
        <td className='table-action-column'>
          {actionButton(row)}
        </td>,
      sortable: false,
    },

  ];

  
  const navigateToReviews = (venueId, name, isPublished) => {
    navigate('reviewList', { state: { id: venueId, name: name, isPublished: isPublished } })
  }

  const updatePublish = (venueId, value) => {
    let body = {
      status: !value
    }
    Headless.updatePublishStastus(venueId, body).then((out) => {
      setReload(!reload)
      setShowToast(true);
      setToastMessage({
        variant: 'success',
        message: value ? 'Studio unpublished successfully!' : 'Studio published successfully!'
      })
    })
      .catch((err) => {
        let item = {
          id: venueId,
          message: err.response.data.status
        }
        setShowWarning(item)
      })
  }

  const updateBookable = (venueId, value) => {
    let body = {
      status: !value
    }
    Headless.updateBookableStatus(venueId, body).then((out) => {
      setReload(!reload)
      setShowToast(true);
      setToastMessage({
        variant: 'success',
        message: 'Payment accespt status updated successfully!'
      })
    })
      .catch((err) => {
        setError(
          {
            variant: "danger",
            message: 'Status update failed'
          }
        )
      })

  }

  const handleWarning = () => {
    setShowWarning(null);
  }



  const onStudioDelete = async () => {
    try {
      Headless.deleteStudio(chosenId).then((out) => {
        setReload(!reload);
        setShowToast(true);
        setToastMessage({
          variant: 'success',
          message: 'Studio deleted successfully!'
        })
      })
        .catch((err) => {
          setError(
            {
              variant: "danger",
              message: 'Studio deletion failed!'
            }
          )
        })
    }
    catch (err) {
      setShowToast(true);
      setToastMessage({
        variant: 'success',
        message: 'Venue deletion failed!'
      })
    }
  }

  const actionButton = (item) => {
    return (
      <Dropdown drop="up">
        <Dropdown.Toggle variant="default" id="dropdown-basic" className='actionDropdown'>
          <img alt="" src={actionIcon} width={30} height={30} />
        </Dropdown.Toggle>
        <Dropdown.Menu className='action-menu'>
          <Dropdown.Item href={`/studios/registeredStudios/${item._id}`} onClick={(e) => { }}>View</Dropdown.Item>
          {!item.isPublished &&
            <Dropdown.Item href={`/studios/registeredStudios/editStudio/${item._id}`} onClick={(e) => { }}>Edit</Dropdown.Item>
          }
          {!item.isPublished &&
            <Dropdown.Item href='#' onClick={(e) => { setChosenId(item._id); setShow(true) }}>Delete</Dropdown.Item>
          }
          <Dropdown.Item href="#" onClick={(e) => { updatePublish(item._id, item.isPublished) }}>
            {item.isPublished ? "Unpublish" : "Publish"}
          </Dropdown.Item>
          {/* <Dropdown.Item href="#" onClick={(e) => { updateBookable(item._id, item.isAcceptPayment) }}>
            {item.isAcceptPayment ? "Set Not Bookable" : "Set Bookable"}
          </Dropdown.Item> */}
          {/* <Dropdown.Item href="#" onClick={(e) => { navigateToGrounds(item._id, item.name, item.isPublished) }}>Ground Management</Dropdown.Item> */}
          <Dropdown.Item href="#" onClick={(e) => { navigateToReviews(item._id, item.name, item.isPublished) }}>View Reviews</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    )
  }

  function filter() {
    return (
      <div style={{ width: 260, height: '100%' }}>
        {openFilter &&
          <div
            className="filter-close"
            onClick={() => setOpenFilter(false)}
          />
        }
        <div className="ml-3 slider-container">
          <p style={{ fontSize: 20 }} className="mb-0 mt-3">Filter</p>
          <div style={{ width: 100, height: 3, overflow: 'hidden', backgroundColor: 'gray' }}></div>
          <p className="mb-0 mt-3">Date Range</p>
          <div className='row ml-0'>
            <div style={{ width: '45%', overflow: 'hidden' }}>
              <DatePicker
                selected={startDate != '' ? startDate : new Date().setMonth(new Date().getMonth() - 1)}
                onChange={(date) => setStartDate(date)}
                dateFormat="d-MMM-yyyy"
                className="date-picker"
                maxDate={endDate ? endDate : new Date()}
              />
            </div>
            <div style={{ width: '45%', overflow: 'hidden', marginLeft: 10 }}>
              <DatePicker
                selected={endDate != '' ? endDate : new Date()}
                onChange={(date) => setEndDate(date)}
                dateFormat="d-MMM-yyyy"
                className="date-picker"
                minDate={startDate ? startDate : new Date().setMonth(new Date().getMonth() - 1)}
              />
            </div>
          </div>
          <p className="mb-3 mt-3">Country</p>
          <CountryDropdown onCountrySelect={(val) => { setSelectedCountry(val) }} selectedCountry={selectedCountry} />
          <p className="mb-0 mt-3">Status</p>
          <div className="radiobtn" onClick={(val) => handleRadio(val.target.value)}>
            {[true, false].map((item) => (
              <span>
                <input
                  type="radio" className='mr-1' value={item} name='status' checked={selectedStatus == item ? true : false} />
                {item ? 'Published' : 'Draft'}
              </span>
            ))}
          </div>

          <div className=" ml-1 row">
            <div
              className="filter-submit mt-3"
              onClick={onPressFilter}
            >
              <p className="ml-3 mr-3 mt-3 mb-3">Filter</p>
            </div>
            <div
              className="filter-clear mt-3"
              onClick={onPressClear}
            >
              <p className="ml-3 mr-3 mt-3 mb-3">Clear</p>
            </div>
          </div>
        </div>
      </div>
    )
  }


  return (
    <>
      {ownerName &&
        <Header title='Owner' value={ownerName} onClick={() => { navigate(-1) }} />
      }
      <div className="card h-100">
        <Sidebar
          pullRight
          sidebar={filter()}
          open={openFilter}
          styles={{
            sidebar:
            {
              background: "white",
              overflow: "visible",
              zIndex: 20
            },
            overlay:
            {
              backgroundColor: "transparent"
            }
          }}
        >
          <div className="card-body">
            <div className="d-flex align-items-center table-header-menu justify-content-between">
              <div className="d-flex align-items-center">
              </div>
              {!ownerName && <div className="d-flex align-items-center">
                <NavLink to={`newStudio`} className="d-flex">
                  <span className="btn filter-btn">
                    Create Studio
                  </span>
                </NavLink>
              </div>}
            </div>
            <div className="d-flex align-items-center table-header-menu justify-content-between">
              <div className="d-flex align-items-center">
                <p className="mr-3">Show</p>
                <p className="mr-3">{limit}</p>
                <Dropdown>
                  <Dropdown.Toggle variant="default" id="dropdown-basic">
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item href="#" onClick={(e) => onToggleLimit(e, 10)}>10</Dropdown.Item>
                    <Dropdown.Item href="#" onClick={(e) => onToggleLimit(e, 25)}>25</Dropdown.Item>
                    <Dropdown.Item href="#" onClick={(e) => onToggleLimit(e, 50)}>50</Dropdown.Item>
                    <Dropdown.Item href="#" onClick={(e) => onToggleLimit(e, 100)}>100</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              {!ownerName &&
                <div className="d-flex align-items-center">
                  <p className="mr-3">Search</p>
                  <input className="form-control" placeholder="Search" onChange={(e) => onSearch(e.target.value.trim())} />
                  <span className="btn filter-btn" onClick={() => setOpenFilter(true)}>
                    Filter
                    {Object.keys(filters).length > 0 ?
                      <span>
                        &#9733;
                      </span>
                      : null}
                  </span>
                </div>
              }
            </div>
            {!loading && studios.length > 0 ?
              <div className="dataHeader">
                <p className="mr-3">
                  Showing {page > 1 ? (page - 1) * limit : 1} to {page * limit < total ? page * limit : total} of {total} entries {Object.keys(filters).length > 0 ? `(filtered)` : ''}
                </p>
              </div>
              : null}

            <div className="table-responsive">
              {loading ?
                <div className="d-flex justify-content-center">
                  <Loader
                    type="TailSpin"
                    color="#000"
                    height={30}
                    width={30}
                  />
                </div> :
                <DataTable
                  columns={columns}
                  data={studios}
                  customStyles={customTableStyles}
                />
              }
            </div>
          </div>
          {!loading && studios.length > 0 ?
            <div className="d-flex card-footer align-items-center justify-content-between">
              <p className="page-data-count">
                Showing {page > 1 ? (page - 1) * limit : 1} to {page * limit < total ? page * limit : total} of {total} entries
              </p>
              <div>
                <Pagination pages={pages} current={page} onChange={changePage} />
              </div>
            </div>
            : null}
          <CustomToast variant={toastMessage.variant} message={toastMessage.message} show={showToast} onClose={() => { setShowToast(false) }} />
         
          <ConfirmPopup show={show} message="Are you sure to delete this studio?" submitButtonText='Confirm' cancelButtonText='Close' handleConfirm={() => { setShow(false); onStudioDelete() }} handleDismiss={() => { setShow(false) }} />

        </Sidebar>
      </div>
    </>
  );

}