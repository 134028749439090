import React from 'react';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from "@material-ui/core";

const SearchableInput = ({ serachResults, value, setFieldValue, onChange, placeholder, disabled }) => {
  return (
    <Autocomplete
      options={serachResults}
      getOptionLabel={option => option.name}
      value={value}
      onChange={async (e, val) => {
        if (val != null) {
          setFieldValue(val);
        }
      }}
      className="searchable-input"
      disabled={disabled}
      noOptionsText={'Search here'}
      renderInput={params => {
        return (
          <TextField
            {...params}
            className="form-control"
            label={placeholder}
            variant="outlined"
            fullWidth
            onChange={(val) => {val.target.value !== '' && val.target.value.length>2 && onChange(val.target.value)}}
            hiddenLabel
          />
        )
      }}
    />
  );
};

export default SearchableInput;