import { send, setHeader } from "../utils/api";



export async function getTrainerList(limit, page, search, filter) {
    let body = {
        filter: filter
    }
    const token = localStorage.getItem('token');
    setHeader('Authorization', `Bearer ${token}`);
    let url = `getTrainer?size=${limit}&page=${page}`;
    if (search) {
        url += `&search=${search}`;
    }
    let data = await send(url, "post", body);
    return data;
}



export async function getOwnerDetails(id) {
    const token = localStorage.getItem('token');
    setHeader('Authorization', `Bearer ${token}`);
    let url = `getSingleTrainer/${id}`;
    let data= await send(url,"get");
    return data;
}

export async function changeUserStatus(id, body) {
    const token = localStorage.getItem('token');
    setHeader('Authorization', `Bearer ${token}`);
    let url = `updateOwnerStatus/${id}`
    let data= await send(url,"post",body);
    return data;
}





