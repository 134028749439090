import React, { useState, useEffect } from 'react';
import './Trainers.scss';
import * as Headless from "./Trainers.headles.js";
import {
    useParams, NavLink
} from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Loader from "react-loader-spinner";
import { Modal, Button } from 'react-bootstrap';
import 'reactjs-popup/dist/index.css';
import CreateOwner from '../components/studioManagement/owners/createOwner';
import CustomToast from '../components/CustomToast';
import ConfirmPopup from '../components/confirmPopup';
import { Typography } from '@material-ui/core';
import { Breadcrumbs } from '@material-ui/core';
import { Link } from '@material-ui/core';
import backIcon from '../../assets/img/backicon.png';

export function TrainerDetails(props) {
    let { id } = useParams();
    const navigate = useNavigate();
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [show, setShow] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState({ variant: 'success', message: 'Manager created successfully!' });
    const [ownerData, setOwnerData] = useState({
        name: '',
        email: '',
        mobile: '',
        location: '',
        status: 1,
        ownerName:1,
        ownerEmail:1
    });

    const getOwnerDetails = async () => {
        setLoading(true);
        try {
            const response = await Headless.getOwnerDetails(id);
            console.log(response.data.data);
            
            if(response.data && response.data.data) {
                setData(response.data.data);
            }
            setLoading(false);
        } catch (err) {
            setLoading(false);
        }
    }

    useEffect(() => {
        getOwnerDetails();
    }, []);
    useEffect(() => {
      console.log("Data",data);
      
    }, [data])
    

    

    const onChangeUserStatus = async () => {
        let status = data.status === 0 ? 1 : 0
        try {
            const response = await Headless.changeUserStatus(id, { status: status });
            setToastMessage({
                variant: 'success',
                message: data.status === 0 ? 'Manager enabled successfully!' : 'Manager disabled successfully!'
            })
            setShowToast(true);
            getOwnerDetails()
        } catch (err) {
            setToastMessage({
                variant: 'success',
                message: data.status === 0 ? 'Enabling manager failed!' : 'Disabling manager failed!'
            })
            setShowToast(true);
        }
    }

   

    const onUserUpdate = async () => {
        setOwnerData({
            name: data.name,
            email: data.email,
            mobile: data.mobileNumber,
            location: data.location,
            status: data.status,
            ownerName:data.ownerName,
            ownerEmail:data.ownerEmail
        })
        setShowModal(!showModal);
    }


    if (loading) {
        return (
            <div className="d-flex justify-content-center">
                <Loader
                    type="TailSpin"
                    color="#000"
                    height={30}
                    width={30}
                />
            </div>
        )
    } else if (!data) {
        return (
            <div className="text-center">
                <h3>Unable to get related data!</h3>
                <NavLink className="btn btn-primary" to="../trainers">Go Back</NavLink>
            </div>
        )
    } else if (data.isDeleted) {
        return (
            <div className="text-center">
                <h3>This manager has been removed!</h3>
                <NavLink className="btn btn-primary" to="../trainers">Go Back</NavLink>
            </div>
        )
    }

    return (
        <>
            {loading ? <div className="d-flex justify-content-center">
                <Loader
                    type="TailSpin"
                    color="#000"
                    height={30}
                    width={30}
                />
            </div> :
                <>
                <div role="presentation" className='card p-3 my-2 custom-breadcrumbs'>
                        <div className='row align-items-center'>
                            <div className='p-2' onClick={() => { navigate(-1) }}>
                                <img src={backIcon} width={30} height={30} />
                            </div>
                            <div className='nav-container p-2'>
                                <Breadcrumbs aria-label="breadcrumb">
                                    <Link color="red" onClick={() => { navigate('../') }} className='custom-breadcrumb-link'>
                                        Home
                                    </Link>
                                    <Link color="inherit" onClick={() => { navigate('../trainers') }}>
                                        Trainers
                                    </Link>
                                    <Typography color="text.primary" className='custom-breadcrumb-p'>Manager Details</Typography>
                                </Breadcrumbs>
                                <div className='mt-1'>
                                    {data.name ? `${data.name} : Details` : ''}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 mb-3">
                            <div className="card">
                                <div className="card-body">
                                    <CustomToast variant={toastMessage.variant} message={toastMessage.message} show={showToast} onClose={() => { setShowToast(false) }} />
                                    <table className="table table-responsive">
                                        <tbody className="no-border details-table">
                                            <tr>
                                                <td><b>Name</b></td>
                                                <td>{data.name}</td>
                                            </tr>
                                            <tr>
                                                <td><b>Email</b></td>
                                                <td>{data.email}</td>
                                            </tr>
                                            <tr>
                                                <td><b>Mobile Number</b></td>
                                                <td>{data.mobileNumber}</td>
                                            </tr>
                                            <tr>
                                                <td><b>Location</b></td>
                                                <td>{data.location}</td>
                                            </tr>
                                            <tr>
                                                <td><b>Owner Name</b></td>
                                                <td>{data.ownerName}</td>
                                            </tr>
                                            <tr>
                                                <td><b>Owner Email</b></td>
                                                <td>{data.ownerEmail}</td>
                                            </tr>
                                            <tr>
                                                <td><b>Status</b></td>
                                                <td>{data.status === 0 ? 'Disabled' : 'Enabled'}</td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row content-wrapper">
                        <Button variant="outline-primary" className="pitchBooking_owners_transaprentButton" onClick={() => { onUserUpdate() }}>Edit</Button>
                        <Button variant="outline-success" className="pitchBooking_owners_transaprentButton" onClick={() => { onChangeUserStatus() }}>{data.status === 0 ? 'Enable' : 'Disable'}</Button>
                    </div>
                    <Modal show={showModal} onHide={() => {
                        setShowModal(false);
                    }} centered>
                        <Modal.Header closeButton>
                            <Modal.Title>{'Edit Manager'}</Modal.Title>
                        </Modal.Header>
                       
                    </Modal>
                </>}
        </>
    );

}