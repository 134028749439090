
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import './custompush.scss';
import { Formik, Field, Form } from 'formik';
import CountryDropdown from '../../components/countryDropdown';
import RadioButton from '../../components/radioButton';
import CloaseButton from '../../components/closeButton';
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import TimePicker from 'react-time-picker';
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';
import { useSelector } from "react-redux";
import CustomInput from '../../components/customInput';
import MultiSelectDropdown from "../../components/multiSelect";
import SingleSelect from "../../components/singleSelect";
import * as ownerApis from '../../pitchBooking/owners/Owners.headless';
import Loader from "react-loader-spinner";
import { getNewCountryList } from '../../utils/functions';
import SearchableInputNew from '../../components/searchableInput';
import {getTimeSlots } from "../../slices/auth.slice";
import { format,formatToUTC24Time } from "../../utils/dateFunctions";
import { getStudioList } from '../../studioManagement/studio/studio.headless';
import SearchableInput from '../../components/searchableInput';
import SearchableInputStudio from '../../components/searchableInputStudio';
const pushSchema = Yup.object().shape({

  title: Yup.string().trim().required('Broadcast title required'),

  message: Yup.string().trim().required('Please enter broadcast message')
    .max(160, 'Text cannot exceed 160 characters'),

});

export function CreateCustomPush({ createCustomPush, data, fromEdit, selectedCountry, onCancel }) {
  const [loading, setLoading] = useState(false);
  const countries = getNewCountryList();
  const timeSlots = useSelector(getTimeSlots);
  const [selectedScreen, setSelectedScreen] = useState([{ id: 0, name: 'Home' }]);
  const [selectedCountries, setSelectedCountries] = useState(countries);
  const [searchTeams, setSearchTeams] = useState([]);
  const [teamName, setTeamName] = useState({ teamName: '' });
  const [teams, setTeams] = useState([])
  const [scheduleDate, setScheduleDate] = useState('');
  const [selectedTeams, setSelectedTeams] = useState([]);
  const [text, setText] = useState('');
  const [time, setTime] = useState('');
  const [selectedTime, setSelectedTime] = useState('12:00'); // Initialize with a default time
  const maxCharacters = 100; // Set your desired character limit
  const screenList = [{ id: 0, name: 'Home' }, { id: 1, name: 'My Teams' }, { id: 2, name: 'My Games' }, { id: 3, name: 'Friends' }, { id: 4, name: 'Book a Pitch' }, { id: 5, name: 'Offers' }, { id: 6, name: 'Profile' }, { id: 7, name: 'Venue Offers' }]
  const onSubmit = (values) => {
    console.log("submit working values are", values)
    if (!values.tabType) {
      console.log("tabtype is not avilable")
      values.tabType = "0"
    }
    if (values.type === 1 && values.countries.length === 0) {
      let d = []
      setSelectedCountries(countries);
      countries.forEach(item => {
        d.push(item.value)
      })
      values.countries = d;
    }
    createCustomPush(values)
  }
  const onCountrySelect = (countries, setFieldValue) => {
    let d = []
    setSelectedCountries(countries);
    countries.forEach(item => {
      d.push(item.value)
    })
    setFieldValue('countries', d);
  }
  useEffect(() => {
    console.log("selected teams", selectedTeams)
  }, [selectedTeams])

  const onTeamSelect = (team, setFieldValue) => {
    console.log("teams are", team)
    let n = { ...teamName };
    n.name = team.teamName;
    setTeamName({ ...n });
    const isDuplicate = selectedTeams.some((item) => item._id == team._id);
    if (!isDuplicate) {
      setSelectedTeams((prevItems) => [...prevItems, team]);
      let temp = [...teams];
      setTeams([...teams, team._id]);
      temp.push(team._id)
      setFieldValue('studios', temp);
    }

  }
  const handleTimeChange = (time) => {
    setSelectedTime(time);
  };


  const onChangeTeamText = async (text) => {
    console.log("hello");
    try {
      let resp = await getStudioList(10, 1, text, {},"");
      console.log("Response",resp);
      console.log("Data 4 times",resp.data.data.data);
      setSearchTeams(resp.data.data.data)
    }
    catch (err) {
      console.log("ERror",err)
      setSearchTeams([]);
    }
  // }
  // const getTaxType = (type) => {
  //   switch (type) {
  //     case 1: return '%'
  //     case 2: return ''
  //     default: return ''
  //   }
    
  //   let n = { ...teamName };
  //   n.teamName = text;
  //   setTeamName({ ...n });
  //   try {
  //     let resp = await ownerApis.getTeamsList(10, 1, text, {});
  //     setSearchTeams(resp.data.data.data)
  //   }
  //   catch (err) {
  //     setSearchTeams([]);
  //   }
  }
  const onScreenSelect = (id, setFieldValue) => {
    switch (id) {
      case 0: {
        //home screen
        setFieldValue('tabType', '0');
        break;
      }
      case 1: {
        //my teams screen
        setFieldValue('tabType', '5');
        break;
      }
      case 2: {
        //my games screen
        setFieldValue('tabType', '6');
        break;
      }
      case 3: { 
        //friends tab
        setFieldValue('tabType', '7');  
        break;
      }
      case 4: {
        //Book a pitch
        setFieldValue('tabType', '19');
        break;
      }
      case 5: {
        //Offers main
        setFieldValue('tabType', '21');
        break;
      }
      case 6: {
        //Profile page
        setFieldValue('tabType', '10');
        break;
      }
      case 7: {
        //Venue offers
        setFieldValue('tabType', '20');
        break;
      }
    }
  }
  const getStartTimeId=(time)=>{
    let slot = timeSlots.find((val) => val.from == time)
      if (slot) {
        return slot.id
      } else {
        return null
      }
  }
  const removeItem = (id, setFieldValue) => {
    // Use the filter function to create a new array without the item with the specified ID
    const updatedItems = selectedTeams.filter((item) => item._id !== id._id);
    const updatedTeams = teams.filter((item) => item != id._id);
    console.log("updatexc Teams", updatedTeams)
    setSelectedTeams(updatedItems);
    setTeams(updatedTeams)
    setFieldValue("teams", updatedTeams)
    console.log("remove item", id)
  };
  return (
    <>
      {loading &&
        <div>
          <Loader
            type="TailSpin"
            color="#000"
            height={30}
            width={30}
            className="loader-parent"
          />
        </div>
      }

      <div className='card-body'>
        <Formik
          initialValues={data}
          validationSchema={pushSchema}
          onSubmit={onSubmit}
          validateOnBlur={false}
          validateOnChange={false}
          onReset={onCancel}
        >

          {({ errors, touched, isSubmitting, values, setFieldValue, handleSubmit }) => (

            <Form className="form">
              <div class="col-md-12 col-sm-12 col-xs-12 pl-0">
                <label
                  className="form-label"
                  htmlFor="first-name-column"
                >
                  Choose target audience
                </label>
                <div role="group" aria-labelledby="checkbox-group" name="type" className="radio-group">
                  <RadioButton value="1" setFieldValue={() => { !fromEdit && setFieldValue('type', 1) }} label='Country' checked={values.type === 1 ? true : false} name="type" />
                  <RadioButton value="2" setFieldValue={() => { !fromEdit && setFieldValue('type', 2) }} label='Studios' checked={values.type === 2 ? true : false} name="type" />
                </div>


              </div>
              <div class="col-md-12 col-sm-12 col-xs-12 underline" />
              <div className="row">
                {values.type === 1 &&
                  <div className="col-md-6 col-12">
                    <div className="mb-1">


                      <div className="mb-3">
                        <MultiSelectDropdown options={countries} label="Contries" selectedValues={selectedCountries} setFieldValue={(val) => {
                          onCountrySelect(val, setFieldValue)
                        }} placeholder="Choose Country(s)" />
                        {errors.days && touched.days && (
                          <span className="error text-danger">{errors.days}</span>
                        )}
                      </div>
                      {/* <SearchableInput serachResults={searchOwners} placeholder='Search Manager'
                      setFieldValue={(val) => { setFieldValue('ownerId', val._id) }} onChange={(val) => { onChangeOwnerText(val) }} value={ownerName} disabled={fromEdit} /> */}
                      {/* {errors.ownerId && touched.ownerId && (
                      <span className="error text-danger">{errors.ownerId}</span>
                    )} */}
                    </div>
                  </div>
                }
                {values.type === 2 &&
                  <div className="col-md-6 col-12">
                    <div className="mb-3">
                      <label
                        className="form-label"
                        htmlFor="first-name-column"
                      >
                        Studio Name:
                      </label>
                      <SearchableInputStudio serachResults={searchTeams} placeholder='Search Studios'
                        setFieldValue={(val) => { onTeamSelect(val, setFieldValue); setTeamName(val) }} onChange={(val) => { onChangeTeamText(val) }} value={teamName} disabled={fromEdit} />

                    </div>

                  </div>

                }
                {values.type === 2 &&
                  <div className="col-md-6 col-12">
                    <div className="mb-2">
                      <label
                        className="form-label"
                        htmlFor="first-name-column"
                      >
                        {selectedTeams.length > 0 ? "Selected Teams" : ""}

                      </label>
                    </div>

                    {selectedTeams.map((item, index) => (
                      <>
                        {/* <div className=''> */}
                        <CloaseButton text={item.studioName} item={item} onClick={(item) => removeItem(item, setFieldValue)} />
                        {/* </div> */}
                      </>
                    ))}
                  </div>

                }
                {/* <CloaseButton text="Hello button"/> */}
                <div className="col-md-6 col-12">
                  <div className="mb-3">
                    <SingleSelect label='Choose Screen' options={screenList} selectedValues={selectedScreen} placeholder='Select Screen' setFieldValue={(val) => {
                      onScreenSelect(val.id, setFieldValue);
                      setSelectedScreen([val])
                    }} fieldName="name" />
                    {/* {errors.screenName && touched.screenName && (
                          <span className="error text-danger">{errors.screenName}</span>
                        )} */}
                  </div>
                  {/* {errors.screenName && touched.screenName && (
                        <span class="error text-danger">{errors.screenName}</span>
                      )} */}
                </div>
              </div>

              <div className="mb-1 mt-1">
                <label
                  className="form-label"
                  htmlFor="first-name-column"
                >
                  Title
                </label>
                <Field
                  type="text"
                  id="first-name-column"
                  className="form-control"
                  placeholder="Enter title"
                  name="title"
                />
                {errors.title && touched.title && (
                  <span class="error text-danger">{errors.title}</span>
                )}
              </div>
              <div className="mb-1 mt-1">
                <label
                  className="form-label"
                  htmlFor="first-name-column"
                >
                  Message
                </label>
                <Field
                  type="text"
                  className="form-control"
                  name="message"
                  as="textarea"
                  rows="3"
                  placeholder="Enter broadcast message here..."

                />
                {errors.message && touched.message && (
                  <span class="error text-danger">{errors.message}</span>
                )}
                {/* <p>
        Characters remaining: {maxCharacters - text.length}
      </p> */}
              </div>
              <div class="col-md-12 col-sm-12 col-xs-12 underline" />
              <div class="col-md-12 col-sm-12 col-xs-12 pl-0">
                <label
                  className="form-label"
                  htmlFor="first-name-column"
                >
                  Schedule braodcast message
                </label>
                <div role="group" aria-labelledby="checkbox-group" name="type" className="radio-group">
                  <RadioButton value="1" setFieldValue={() => { !fromEdit && setFieldValue('scheduleType', 1) }} label='Now' checked={values.scheduleType === 1 ? true : false} name="scheduleType" />
                  {/* <RadioButton value="2" setFieldValue={() => { !fromEdit && setFieldValue('scheduleType', 2) }} label='Later' checked={values.scheduleType === 2 ? true : false} name="scheduleType" /> */}
                </div>

                {
                  values.scheduleType === 2 ? (<>
                    <div className="col-md-6 col-12 d-flex">
                      <div className='' style={{}}>
                        <DatePicker
                          selected={scheduleDate != '' ? scheduleDate : ''}
                          onChange={(date) => 
                            {
                              console.log("date selected is", date)
                              const isoString = `${date.getFullYear()}-${(date.getMonth() + 1)
                                .toString()
                                .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}T${date
                                .getHours()
                                .toString()
                                .padStart(2, "0")}:${date.getMinutes().toString().padStart(2, "0")}:${date
                                .getSeconds()
                                .toString()
                                .padStart(2, "0")}.${date.getMilliseconds().toString().padStart(3, "0")}Z`;
                              
                              console.log("isoString",isoString);
                              console.log("date.toISOString()",date.toISOString());
                              setScheduleDate(date);
                              console.log("selected after")
                              
                              setFieldValue("broadcastDate",isoString);
                            }
                           
                          
                          }
                          timeZone="UTC" 
                          dateFormat="d-MMM-yyyy"
                          className="date-picker range-picker-min-height"
                          minDate={new Date()}
                          maxDate={null}
                          placeholderText="Choose date"
                        />
                        {/* {showStartError &&
                          <span className="error text-danger">{startError}</span>
                        } */}
                      </div>
                        
                      <div className='ml-2' >
                        {/* <DatePicker
                          selected={time != '' ? time : ''}
                          onChange={(time) => 
                            {
                              setTime(time)
                              setFieldValue("broadcastTime",getStartTimeId(formatToUTC24Time(time)));
                            }
                            
                          
                          }
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={30}
                          timeCaption="Time"
                          dateFormat="h:mm aa"
                          className="date-picker range-picker-min-height form-control"
                          placeholderText={"Choose time"}
                        /> */}
                         <TimePicker value={selectedTime} onChange={(time)=>{
                          handleTimeChange(time);
                          setFieldValue("broadcastTime",time)
                         }} />
                      </div>
                    </div>
                    <span className="error text-primary">We are using GMT time (It may delay max 15 Min)</span>
                    {/* <div className="col-md-6 col-12">
                      
                    </div> */}

                  </>)
                    : (<></>)
                }


              </div>
              <div class="col-md-12 col-sm-12 col-xs-12 underline" />
              <div class="col-md-12 col-sm-12 col-xs-12 pl-0">
                <label
                  className="form-label"
                  htmlFor="first-name-column"
                >
                  Broadcast type
                </label>
                <div role="group" aria-labelledby="checkbox-group" name="type" className="radio-group">
                  <RadioButton value="1" setFieldValue={() => { !fromEdit && setFieldValue('pushType', 1) }} label='In-App' checked={values.pushType === 1 ? true : false} name="pushType" />
                  <RadioButton value="2" setFieldValue={() => { !fromEdit && setFieldValue('pushType', 2) }} label='Push' checked={values.pushType === 2 ? true : false} name="pushType" />
                  <RadioButton value="3" setFieldValue={() => { !fromEdit && setFieldValue('pushType', 3) }} label='Both' checked={values.pushType === 3 ? true : false} name="pushType" />
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-12">
                  <button
                    type="submit"
                    className="btn filter-btn mr-3 ml-0 waves-effect waves-float waves-light"
                    disabled={isSubmitting}
                  >
                    Submit
                  </button>
                  <button
                    type="reset"
                    className="btn btn-outline-secondary waves-effect"
                    disabled={isSubmitting}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  )
}