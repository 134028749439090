import React, { useState, useEffect } from 'react';
import * as Headless from "./payment.headless";
import {
  NavLink, useLocation, useNavigate
} from "react-router-dom";
import { Dropdown } from 'react-bootstrap';
import Loader from "react-loader-spinner";
import _ from 'lodash';
import Sidebar from "react-sidebar";
import { Typography } from '@material-ui/core';
import { Breadcrumbs } from '@material-ui/core';
import { Link } from '@material-ui/core';
import DataTable from 'react-data-table-component';
import Pagination from '../../components/Pagination';
import { customTableStyles } from '../../utils/styles'
import { format } from '../../utils/dateFunctions';
import backIcon from '../../../assets/img/backicon.png';
import "./transaction.scss"
import { getTransactionStatus, getTransactionStatusColor, getRefundStatus, getRefundStatusColor } from '../../utils/functions';

export function Transactions(props) {
  const navigate = useNavigate();
  let location = useLocation();
  const venueId = location && location.state && location.state.id ? location.state.id : undefined;
  const venueName = location && location.state && location.state.name ? location.state.name : undefined;
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(1);
  const [total, setTotal] = useState(0);
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState('');
  const [openFilter, setOpenFilter] = useState(false)
  const [filters, setFilters] = useState({})
  const [show, setShow] = useState(false);
  const [showToast, setShowToast] = useState(false);

  const getTransactions = async (l, p, s, filter) => {
    let body = {
      venueId: venueId
    }
    setLoading(true);
    try {
      const response = await Headless.getPayments(l, p, s, filter, body);
      console.log(response.data.data);
      
      setPages(response.data.data.pages);
      setTotal(response.data.data.total);
      setTransactions(response.data.data.data);
      setPage(response.data.data.page);
      setLoading(false);
    } catch (err) {
      setPages(1);
      setTotal(0);
      setTransactions([]);
      setLoading(false);
    }
  }

  const onSearch = _.debounce(setSearch, 500);

  // useEffect(() => {
  //   getTransactions(limit, page, search, filters);
  // }, [page]);

  useEffect(() => {
    getTransactions(limit, 1, search, filters);
  }, [limit, search, filters])

  const onToggleLimit = (e, value) => {
    e.preventDefault();
    setPage(1)
    setLimit(value);
  };
  const changePage = (num) => {
    getTransactions(limit, num, search, filters);
  }
  const columns = [
    {
      name: <th>#</th>,
      selector: (row, index) => index + (limit * (page - 1)) + 1,
      cell: (row, index) =>
        <td className="d-flex">{index + (limit * (page - 1)) + 1}</td>,
      grow: 0.05,
      width: '60px'
    },
    {
      name: <th>REF NO</th>,
      selector: (row, index) => row.referenceId,
      cell: (row, index) =>
        <td>
            {row.referenceId? row.referenceId:'N/A'}
          
        </td>,
      sortable: true,
    },
    {
      name: <th>PAYED BY</th>,
      selector: (row, index) => row.userId.name.toLowerCase(),
      cell: (row, index) =>
        <td style={{ maxWidth: 150 }} className="d-flex">
            {row.userId.name}
        </td>,
      sortable: true,
    },
    {
      name: <th>PAYED TO</th>,
      selector: (row, index) => row.ownerId.name.toLowerCase(),
      cell: (row, index) =>
        <td className="d-flex">
          {row.ownerId.name.toLowerCase()}
        </td>,
      sortable: true,
    },
    {
      name: <th>AMOUNT</th>,
      selector: (row, index) => row.amount,
      cell: (row, index) =>
        <td className="d-flex">
          {row.amount}
        </td>,
      sortable: true,
    },
   
    {
      name: <th>PAYED ON</th>,
      selector: (row, index) => format(row.createdAt, 'DD-MM-YYYY h:mm A'),
      cell: (row, index) =>
        <td className="d-flex">
          {format(row.createdAt, 'DD-MM-YYYY h:mm A')}
        </td>,
      sortable: true,
    },
    {
      name: <th>METHOD</th>,
      selector: (row, index) => (row.method),
      cell: (row, index) =>
        <td className="d-flex">
          {(row.method)}
        </td>,
      sortable: true,
    },
    {
      name: <th>STATUS</th>,
      selector: (row, index) => (row.status),
      cell: (row, index) =>
        <td className="d-flex">
          {(row.status)}
        </td>,
      sortable: true,
    },
   
  ]

  return (
    <>
      <div role="presentation" className='card p-3 my-2 custom-breadcrumbs'>
        <div className='row align-items-center'>
          <div className='p-2' onClick={() => { navigate(-1) }}>
            <img src={backIcon} width={30} height={30} />
          </div>
          <div className='nav-container p-2'>
            <Breadcrumbs aria-label="breadcrumb">
              <Link color="red" onClick={() => { navigate('../../') }} className='custom-breadcrumb-link'>
                Home
              </Link>
             
              <Typography color="text.primary" className='custom-breadcrumb-p'>Transactions</Typography>
            </Breadcrumbs>
            <div className='mt-1'>
              {venueName ? `${venueName} : Transactions` : ''}
            </div>
          </div>
        </div>
      </div>
      <div className="card h-100">
        <Sidebar
          pullRight
          // sidebar={filter()}
          open={openFilter}
          styles={{
            sidebar:
            {
              background: "white",
              overflow: "visible",
              zIndex: 20
            },
            overlay:
            {
              backgroundColor: "transparent"
            }
          }}
        >
          <div className="card-body" style={{ marginBottom:"80px"}}>
            <div className="d-flex align-items-center table-header-menu justify-content-between">
              <div className="d-flex align-items-center">
                <p className="mr-3">Show</p>
                <p className="mr-3">{limit}</p>
                <Dropdown>
                  <Dropdown.Toggle variant="default" id="dropdown-basic">
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item href="#" onClick={(e) => onToggleLimit(e, 10)}>10</Dropdown.Item>
                    <Dropdown.Item href="#" onClick={(e) => onToggleLimit(e, 25)}>25</Dropdown.Item>
                    <Dropdown.Item href="#" onClick={(e) => onToggleLimit(e, 50)}>50</Dropdown.Item>
                    <Dropdown.Item href="#" onClick={(e) => onToggleLimit(e, 100)}>100</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div className="d-flex align-items-center">
                <p className="mr-3">Search</p>
                <input className="form-control" placeholder="Search" onChange={(e) => onSearch(e.target.value.trim())} />
                {/* <span className="btn filter-btn" onClick={() => setOpenFilter(true)}>
                Filter
                {Object.keys(filters).length > 0 ?
                  <span>
                    &#9733;
                  </span>
                  : null}
              </span> */}
              </div>
            </div>
            {!loading && transactions.length > 0 ?
              <div className="dataHeader">
                <p className="mr-3">
                  Showing {page > 1 ? (page - 1) * limit : 1} to {page * limit < total ? page * limit : total} of {total} entries {Object.keys(filters).length > 0 ? `(filtered)` : ''}
                </p>
              </div>
              : null}
            <div className="table-responsive custom-data-table">
              {loading ? <div className="d-flex justify-content-center">
                <Loader
                  type="TailSpin"
                  color="#000"
                  height={30}
                  width={30}
                />
              </div> :
                <DataTable
                  columns={columns}
                  data={transactions}
                  customStyles={customTableStyles}
                />
              }
            </div>
            {!loading && transactions.length > 0 ?
            <div className="d-flex card-footer align-items-center justify-content-between">
              <p className="page-data-count">
                Showing {page > 1 ? (page - 1) * limit : 1} to {page * limit < total ? page * limit : total} of {total} entries
              </p>
              <div>
                <Pagination pages={pages} current={page} onChange={changePage} />
              </div>
            </div>
            :null}
          </div>
         
        </Sidebar>

      </div>
    </>
  );

}