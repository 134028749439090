import React from 'react';
import { MultiSelect } from "react-multi-select-component";
const MultiSelectDropdown = ({ options, label, selectedValues, setFieldValue, placeholder }) => {

  return (
    <>
      <label
        className="form-label"
        htmlFor="first-name-column"
      >
        {label}
      </label>
      <MultiSelect
        options={options}
        value={selectedValues}
        onChange={(val) => { setFieldValue(val) }}
        ClearSelectedIcon={null}
        overrideStrings={{
          "allItemsAreSelected": "All",
          "search": "Search",
          "selectAll": "All",
          "selectAllFiltered": "All",
          "selectSomeItems": placeholder,
        }}
        closeOnChangedValue={false}
        disableSearch={true}
      />
    </>

  );
};

export default MultiSelectDropdown;