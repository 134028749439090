import { send, sendServer, setHeader } from "../../utils/api";
const axios = require('axios');
axios.defaults.withCredentials = true;

export async function getPayments(limit, page, search, filter, ownerId) {
  let body = {};
  if (Object.keys(filter).length !== 0) {
    body.filter = filter;
  }
  if (ownerId) {
    let f = { ...filter }
    f.owner = ownerId
    body.filter = f;
  }
  const token = localStorage.getItem('token');
  setHeader('Authorization', `Bearer ${token}`);
  let url = `getPayments?size=${limit}&page=${page}`;
  if (search) {
    url += `&search=${search}`;
  }
  console.log("getPayments call",body)
  let data = await send(url, "post", body);
  return data;
}