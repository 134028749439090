import React, { useState, useEffect } from 'react';
import './custompush.scss';
import * as Headless from "./custompush.headless";
import {
    useParams, NavLink
} from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Loader from "react-loader-spinner";
import { Modal, Button } from 'react-bootstrap';
import 'reactjs-popup/dist/index.css';
import { Typography } from '@material-ui/core';
import { Breadcrumbs } from '@material-ui/core';
import { Link } from '@material-ui/core';
import dayjs from 'dayjs';
import backIcon from '../../../assets/img/backicon.png';
import Multiselect from 'multiselect-react-dropdown';

export function BroadcastDetails(props) {
    let { id } = useParams();
    const navigate = useNavigate();
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const getMessageDetails = async () => {
        setLoading(true);
        try {
            
            const response = await Headless.getCustomPushDetails(id);
            if (response.data && response.data.data) {
                console.log("response.data.data", response.data.data)
                setData(response.data.data);
            }
            setLoading(false);
        } catch (err) {
            setLoading(false);
        }
    }
    useEffect(() => {
        getMessageDetails();
    }, []);
    if (loading) {
        return (
            <div className="d-flex justify-content-center">
                <Loader
                    type="TailSpin"
                    color="#000"
                    height={30}
                    width={30}
                />
            </div>
        )
    } else if (!data) {
        return (
            <div className="text-center">
                <h3>Unable to get related data!</h3>
                <NavLink className="btn btn-primary" to="/management/customPush">Go Back</NavLink>
            </div>
        )
    }
    return (
        
        <>
            {loading ? <div className="d-flex justify-content-center">
                <Loader
                    type="TailSpin"
                    color="#000"
                    height={30}
                    width={30}
                />
            </div> :
                <>
                    <div role="presentation" className='card p-3 my-2 custom-breadcrumbs'>
                        <div className='row align-items-center'>
                            <div className='p-2' onClick={() => { navigate(-1) }}>
                                <img src={backIcon} width={30} height={30} />
                            </div>
                            <div className='nav-container p-2'>
                                <Breadcrumbs aria-label="breadcrumb">
                                    <Link color="red" onClick={() => { navigate('../../') }} className='custom-breadcrumb-link'>
                                        Home
                                    </Link>
                                    <Link color="inherit" onClick={() => { navigate('../../management/customPush') }}>
                                        Custom Push
                                    </Link>
                                    <Typography color="text.primary" className='custom-breadcrumb-p'>Broadcast Details</Typography>
                                </Breadcrumbs>
                                <div className='mt-1'>
                                    {data.title ? `${data.title} : Details` : ''}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 mb-3">
                            <div className="card">
                                <div className="card-body">

                                    <table className="table table-responsive">
                                        <tbody className="no-border details-table">
                                            <tr>
                                                <td><b>Title</b></td>
                                                <td>{data.title}</td>
                                            </tr>
                                            <tr>
                                                <td><b>Message</b></td>
                                                <td>{data.message}</td>
                                            </tr>
                                            <tr>
                                                {
                                                    data.countries.length ? (
                                                        <>
                                                            <td><b>Countries</b></td>
                                                            {/* {
                                                                data.countries.map(item => (
                                                                <div className='inline-button'>

                                                                    <td>{item.country}</td>
                                                                </div>))
                                                            } */}
                                                            <Multiselect
                                                                options={data.countries}
                                                                // selectedValues={selectedRefundPolicies}
                                                                onSelect={(val) => {
                                                                    // setSelectedRefundPolicies(val)
                                                                }}
                                                                showCheckbox={false}
                                                                placeholder="List of countries"
                                                                hidePlaceholder={true}
                                                                showArrow={true}
                                                                isObject={true}
                                                                displayValue="country"
                                                                singleSelect={true}
                                                                avoidHighlightFirstOption={true}
                                                                className="country-dropdown-max-height"
                                                            />
                                                        </>
                                                    ) : (<>
                                                        <td><b>Teams</b></td>
                                                        {
                                                            data.teams.map(item => (<>
                                                                <NavLink exact to={item._id ? `/teams/${item._id}` : '#'} className="active-link">
                                                                    <td>{item.teamName}</td>
                                                                </NavLink>
                                                            </>))
                                                        }
                                                    </>)
                                                }

                                            </tr>
                                            <tr>
                                                <td><b>Date of braodcast</b></td>
                                                <td>{dayjs(data.broadcastDate).format('DD MMM YYYY hh:mm A')}</td>
                                            </tr>
                                            <tr>
                                                <td><b>Created At</b></td>
                                                <td>{dayjs(data.createdAt).format('DD MMM YYYY hh:mm A')}</td>
                                            </tr>

                                            <tr>
                                                <td><b>Status</b></td>
                                                <td><span className="badge badge-primary">{data.status}</span></td>
                                            </tr>
                                            <tr>
                                                <td><b>Broadcast type</b></td>
                                                <td><span className="">{data.pushTypej===1?"In-App Notifications":data.pushType===2?"Push Notification":"Push & In-App Notification"   }</span></td>
                                            </tr>

                                            <tr>
                                                <td><b>Users Count</b></td>
                                                <td>{data.count?data.count:"N/a"}</td>
                                                {/* <td>{data.status === "Completed" ? data.count : data.status === "In Progress" ? `In-Progress/${data.count}` : data.status === "Scheduled" ? `Scheduled/${data.count}` : "N/a"}</td> */}
                                            </tr>




                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                </>}
        </>
    );

}