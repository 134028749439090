import React, { useState, useEffect } from 'react';
import './Workouts.scss';
import * as Headless from "./Workouts.headless";
import {
  useNavigate
} from "react-router-dom";
import { Dropdown, Modal } from 'react-bootstrap';


import Loader from "react-loader-spinner";
import _ from 'lodash';
import Sidebar from "react-sidebar";
import DataTable from 'react-data-table-component';
import Pagination from '../../components/Pagination';
import { customTableStyles } from '../../utils/styles'
import {
  validateNumber
} from '../../utils/functions';
import { useSelector } from "react-redux";
import actionIcon from '../../../assets/svg/actionIcon.svg';
import CustomToast from '../../components/CustomToast';
import ConfirmPopup from '../../components/confirmPopup';
import { AddWorkout } from '../../components/workout/addWorkout/addWorkout';
import {getTimeSlots} from '../../slices/auth.slice';
import { convert24To12 } from '../../utils/dateFunctions'

export function Workouts(props) {
  const navigate = useNavigate();
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(1);
  const [total, setTotal] = useState(0);
  const [workouts, setWorkouts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState('');
  const [openFilter, setOpenFilter] = useState(false)
  const [selectedBodypart, setSelectedBodypart] = useState([]);
  const [filters, setFilters] = useState({})
  const [show, setShow] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState({ variant: 'success', message: '' });
  const [chosenId, setChosenId] = useState('');
  const [workoutData, setWorkoutData] = useState({
   name:"",
   bodyPart:"",
   equipment:"",
   target:"",
   secondaryMuscles:[],
   instructions:[],
   beginnerSets:"",
   intermediateSets:"",
   expertSets:"",
   policies:[],
image:""

  });
  const [editPressed, setEditPressed] = useState(false);
  const onSearch = _.debounce(setSearch, 500);

  useEffect(() => {
    getWorkouts(limit, page, search, filters);
  }, [page]);

  useEffect(() => {
    getWorkouts(limit, 1, search, filters);
  }, [limit, search, filters])

  const getWorkouts = async (l, p, s, filter) => {
    setLoading(true);
    try {
      const response = await Headless.getWorkouts(l, p, s, filter);
      console.log(response.data);
      if (response.data && response.data.pages && response.data.total && response.data.data) {
        setPages(response.data.pages);
        setTotal(response.data.total);
        setWorkouts(response.data.data);
      } else {
        setPages(0);
        setTotal(0);
        setWorkouts([]);
      }
      setLoading(false);
    } catch (err) {
      setPages(1);
      setTotal(0);
      setWorkouts([]);
      setLoading(false);
    }
  }

  const columns = [
    {
      name: <th>#</th>,
      selector: (row, index) => index + (limit * (page - 1)) + 1,
      cell: (row, index) =>
        <td className="d-flex">{index + (limit * (page - 1)) + 1}</td>,
      grow: 0.05,
      width: '60px'
    },
    {
      name: <th>Name</th>,
      selector: (row, index) => row.name ? row.name : '',
      cell: (row, index) =>
        <td style={{ maxWidth: 150 }} className="d-flex">
          <span onClick={() => { row._id && navigateToWorkout(row._id) }}>{row.name? row.name : ''}</span>
        </td>,
      sortable: true,
    },
    {
      name: <th>Bodypart</th>,
      selector: (row, index) => row.bodyPart && row.bodyPart.title ? row.bodyPart.title : '',
      cell: (row, index) =>
        <td style={{ maxWidth: 150 }} className="d-flex">
          <span>{row.bodyPart && row.bodyPart.title? row.bodyPart.title : ''}</span>
        </td>,
      sortable: true,
    },
    {
      name: <th>Equipment</th>,
      selector: (row, index) => row.equipment&&row.equipment.title ? row.equipment.title : '',
      cell: (row, index) =>
        <td style={{ maxWidth: 150 }} className="d-flex">
          <span>{row.equipment&&row.equipment.title ? row.equipment.title : ''}</span>
        </td>,
      sortable: true,
    },
    {
      name: <th>Target</th>,
      selector: (row, index) => row.target ? row.target: '',
      cell: (row, index) =>
        <td style={{ maxWidth: 150 }} className="d-flex">
          <span>{row.target ? row.target: ''}</span>
        </td>,
      sortable: true,
    },

    {
      name: <th>Secondary muscles</th>,
      selector: (row, index) => row.secondaryMuscles ? row.secondaryMuscles : '',
      cell: (row, index) =>
        <td style={{ width: 100 }}>
          <ul>
              {row.secondaryMuscles.length?row.secondaryMuscles.map((muscle, index) => (
                <li key={index}>{muscle}</li>
              )):""}
            </ul>
        </td>,
      sortable: false,
    },
    // {
    //   name: <th>Sets & Reps</th>,
    //   selector: (row, index) => row.beginnerSets || row.intermediateSets || row.expertSets,
    //   cell: (row, index) =>
    //     <td style={{ maxWidth: 250 }} className="d-flex">
    //       <ul>
    //             <li>Beginner : {row.beginnerSets.sets} Sets with {row.beginnerSets.minReps} to {row.beginnerSets.maxReps} reps</li>
    //             <li>Intermediate : {row.intermediateSets.sets} Sets with {row.intermediateSets.minReps} to {row.intermediateSets.maxReps} reps</li>
    //             <li>Expert : {row.expertSets.sets} Sets with {row.expertSets.minReps} to {row.expertSets.maxReps} reps</li>
    //         </ul>
    //     </td>,
    //   sortable: false, 
    // },
    {
      name: <th>ACTIONS</th>,
      selector: (row, index) => 'actions',
      cell: (row, index) =>
        <td className='table-action-column'>
          {actionButton(row)}
        </td>,
    },
  ];

  const actionButton = (row) => {
    return (
      <Dropdown drop="up">
        <Dropdown.Toggle variant="default" id="dropdown-basic" className='actionDropdown'>
          <img alt="" src={actionIcon} width={30} height={30} />
        </Dropdown.Toggle>
        <Dropdown.Menu className='action-main'>
          <Dropdown.Item href="#" onClick={(e) => { setChosenId(row._id); onWorkoutSelect('edit', row); }}>Edit</Dropdown.Item>
          <Dropdown.Item href="#" onClick={(e) => { setChosenId(row._id); setShow(!show) }}>Delete</Dropdown.Item>
          {/* <Dropdown.Item href="#" onClick={() => { }}>{row.status && row.status === 1 ? 'Disable' : 'Enable'}</Dropdown.Item> */}
        </Dropdown.Menu>
      </Dropdown>
    )
  }

  const onAddWorkout = async (values) => {

    setShowModal(false);
    try {
      const response = await Headless.addWorkout(values);
      setToastMessage({
        variant: 'success',
        message: 'Workout added successfully!'
      })
      setShowToast(true);
      getWorkouts(limit, page, search, filters)
    }
    catch (err) {
      setToastMessage({
        variant: 'danger',
        message: err.response.data.status ? err.response.data.status : 'Adding workout failed!'
      })
      setShowToast(true);
    }
  }

  const onEditWorkout = (values) => {
    setShowModal(false);
   
    Headless.editWorkout(chosenId, values).then(() => {
      setToastMessage({
        variant: 'success',
        message: 'Workout details edited successfully!'
      })
      setShowToast(true);
      getWorkouts(limit, page, search, filters);
      setChosenId('');
      setEditPressed(false);
    })
      .catch(err => {
        setToastMessage({
          variant: 'danger',
          message: 'Editing workout failed!'
        })
        setShowToast(true);
        setChosenId('');
        setEditPressed(false);
      })
  }

  const onWorkoutSelect = async (from, row) => {
    if (from === 'edit') {
      setEditPressed(true);
      setWorkoutData({
        name:row.name,
        bodyPart:row.bodyPart&&row.bodyPart.title?row.bodyPart.title:"",
        equipment:row.equipment&&row.equipment.title?row.equipment.title:"",
        target:row.target,
        secondaryMuscles:row.secondaryMuscles,
        instructions:row.instructions,
        beginnerSets:row.beginnerSets,
        intermediateSets:row.intermediateSets,
        expertSets:row.expertSets,
        image:row.image.uri
        // policies:row.policies
      })
    } else {
      setEditPressed(false);
      setWorkoutData({
        name:"",
        bodyPart:"",
        equipment:"",
        target:"",
        secondaryMuscles:[],
        beginnerSets:"",
        intermediateSets:"",
        expertSets:"",
        instructions: [{instruction: '' }],
        image:""
      })
    }
    setShowModal(!showModal);
  }
  const onDeleteConfirm = async () => {
    try {
      const response = await Headless.deleteWorkout(chosenId);
      setChosenId('');
      setToastMessage({
        variant: 'success',
        message: 'Workout deleted successfully!'
      })
      setShowToast(true);
      getWorkouts(limit, page, search, filters)
    } catch (err) {
      setToastMessage({
        variant: 'danger',
        message: 'Deleting workout failed!'
      })
      setShowToast(true);
     
      setChosenId('');
    }
  }
  const onToggleLimit = (e, value) => {
    e.preventDefault();
    setPage(1)
    setLimit(value);
  };


  const navigateToWorkout = (workoutId) => {
    navigate(`/workouts/workoutDetails/${workoutId}`);
  }

  return (
    <>
      <div className="card h-100">
        <Sidebar
          pullRight
          // sidebar={filter()}
          open={openFilter}
          styles={{
            sidebar:
            {
              background: "white",
              overflow: "visible",
              zIndex: 20
            },
            overlay:
            {
              backgroundColor: "transparent"
            }
          }}
        >
          <div className="card-body">
            <div className="d-flex align-items-center table-header-menu justify-content-between">
              <div className="d-flex align-items-center" />
              <div className="d-flex align-items-center">
                <div className="d-flex" onClick={() => { onWorkoutSelect('create') }}>
                  <span className="btn filter-btn">
                    Add Workout
                  </span>
                </div>
              </div>
            </div>
            <div className="d-flex align-items-center table-header-menu justify-content-between">
              <div className="d-flex align-items-center">
                <p className="mr-3">Show</p>
                <p className="mr-3">{limit}</p>
                <Dropdown>
                  <Dropdown.Toggle variant="default" id="dropdown-basic">
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item href="#" onClick={(e) => onToggleLimit(e, 10)}>10</Dropdown.Item>
                    <Dropdown.Item href="#" onClick={(e) => onToggleLimit(e, 25)}>25</Dropdown.Item>
                    <Dropdown.Item href="#" onClick={(e) => onToggleLimit(e, 50)}>50</Dropdown.Item>
                    <Dropdown.Item href="#" onClick={(e) => onToggleLimit(e, 100)}>100</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div className="d-flex align-items-center">
                <p className="mr-3">Search</p>
                <input className="form-control" placeholder="Search" onChange={(e) => onSearch(e.target.value.trim())} />
                {/* <span className="btn filter-btn" onClick={() => setOpenFilter(true)}>
                  Filter
                  {Object.keys(filters).length > 0 ?
                    <span>
                      &#9733;
                    </span>
                    : null}
                </span> */}
              </div>
            </div>
            {!loading &&  workouts.length > 0 ?
              <div className="dataHeader">
                <p className="mr-3">
                  Showing {page > 1 ? (page - 1) * limit : 1} to {page * limit < total ? page * limit : total} of {total} entries {Object.keys(filters).length > 0 ? `(filtered)` : ''}
                </p>
                <p className="mr-3">
                  Showing {workouts.length} entries
                </p>
              </div>
              : null}
            <div className="table-responsive">
              {loading ? <div className="d-flex justify-content-center">
                <Loader
                  type="TailSpin"
                  color="#000"
                  height={30}
                  width={30}
                />
              </div> :
                <DataTable
                  columns={columns}
                  data={workouts}
                  customStyles={customTableStyles}
                />
              }
            </div>
          </div>
          {!loading && workouts.length > 0 ?
            <div className="d-flex card-footer align-items-center justify-content-between">
              <p className="page-data-count">
                Showing {page > 1 ? (page - 1) * limit : 1} to {page * limit < total ? page * limit : total} of {total} entries
              </p>
              <div>
                <Pagination pages={pages} current={page} onChange={setPage} />
              </div>
            </div>
            : null}
        </Sidebar>
        <ConfirmPopup show={show} message="Are you sure to delete this workout?" submitButtonText='Confirm' cancelButtonText='Close' handleConfirm={() => { setShow(false); onDeleteConfirm() }} handleDismiss={() => { setShow(false) }} />
        <Modal size="xl" show={showModal} onHide={() => {
          setShowModal(false); if (editPressed) {
            setEditPressed(false);
            setChosenId('');
          }
        }} centered>
          <Modal.Header closeButton>
            <Modal.Title>{editPressed ? 'Edit Workout' : 'Create Workout'}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <AddWorkout onAddWorkout={(values) => { editPressed ? onEditWorkout(values) : onAddWorkout(values) }} onCancel={() => { setShowModal(false) }} data={workoutData} fromEdit={editPressed ? true : false} />
          </Modal.Body>
        </Modal>
        <CustomToast variant={toastMessage.variant} message={toastMessage.message} show={showToast} onClose={() => { setShowToast(false) }} />
      </div>
    </>
  );

}